@tailwind base;
@tailwind components;
@tailwind utilities;

.dark code,
.dark pre {
  background-color: #1a202c;
  color: #cbd5e0;
}

pre {
  overflow: auto;
}

.thread__wrapper {
  padding: 20px;
}
